import SplitType from 'split-type';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger); 

export default function Type() {

    
    const body = document.body;
    const heroTitle = body.querySelectorAll("#hero__title h1");
    const projTitle = body.querySelectorAll("#project__title h1");


    const textSplit = new SplitType(heroTitle, {
      types: "words, chars",
      tagName: "span"
    });

     const projSplit = new SplitType(projTitle, {
      types: "words, chars",
      tagName: "span"
    });


    $("#hero__title h1").each(function (index) {
      let tl = gsap.timeline({ paused: false });
      tl.from($(this).find(".word"), {
        opacity: 0,
        yPercent: 20,
        duration: 0.5,
        delay: 0.5,
        ease: "back.out(1)",
        stagger: 0.1
      });
      gsap.set($(this), { opacity: 1 });
    });

    $("#project__title h1").each(function (index) {
      let tl = gsap.timeline({ paused: false });
      tl.from($(this).find(".char"), {
        opacity: 0,
        y: 50,
        delay: 0.7,
        duration: 0.2,
        ease: "back.out(1)",
        stagger: {
          from: "start", 
          each: 0.05
        }
      });
      gsap.set($(this), { opacity: 1 });
    });

    const anilines = gsap.utils.toArray(".js-lines");

    anilines.forEach((target) => {
      let animation = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          markers: false,
          toggleActions: "play none none none"
        }
      });
      let split = new SplitType(target.querySelector(".js-lines p"), { types: "lines", tagName: "span" });
      animation.from(split.lines, {
        opacity: 0,
        y: 20,
        delay: 0.5,
        duration: 0.5,
        ease: "back.out(1)",
        stagger: {
          from: "start", 
          each: 0.1
        }
      });
    });



    const anichars = gsap.utils.toArray(".js-chars");

    anichars.forEach((target) => {
      let animation = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          markers: false,
          toggleActions: "play none none none"
        }
      });
      let split = new SplitType(target.querySelector(".js-chars .js-animate"), { types: "chars", tagName: "span" });
      animation.from(split.chars, {
        opacity: 0,
        y: 50,
        delay: 0.7,
        duration: 0.2,
        ease: "back.out(1)",
        stagger: {
          from: "start", 
          each: 0.05
        }
      });
    }); 

};
