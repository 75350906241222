export default function Navigation() {
    (function () {

        var doc = document.documentElement;
        var w = window;

        var curScroll;
        var prevScroll = w.scrollY || doc.scrollTop;
        var curDirection = 0;
        var prevDirection = 0;

        var header = document.getElementById("top-wrapper");
        var toggled;
        var threshold = 200;

        var checkScroll = function () {
            curScroll = w.scrollY || doc.scrollTop;
            if (curScroll > prevScroll) {
                // scrolled down
                curDirection = 2;
            } else {
                //scrolled up
                curDirection = 1;
            }

            if (curDirection !== prevDirection) {
                toggled = toggleHeader();
            }

            prevScroll = curScroll;
            if (toggled) {
                prevDirection = curDirection;
            }
        };

        var toggleHeader = function () {
            toggled = true;

            if (curDirection === 2 && curScroll > threshold) {
                header.classList.add("hide");
                header.classList.add("fixed");
            } else {
                toggled = false;
            }

            if (curDirection === 1) {
                header.classList.remove("hide");
                if (curScroll < threshold) header.classList.remove("fixed");
            } else {
                toggled = false;
            }

            return toggled;
        };

        window.addEventListener("scroll", checkScroll);

    })();

    $('#menu-button').on('click', function (e) {
        $('body').toggleClass('nav-active');
        $(this).toggleClass('is-active');
        $(this).parent().toggleClass('is-active');
        $('#navigation-wrapper').toggleClass('is-active');
        $('#top-wrapper').toggleClass('is-active');
        $('#navigation__lines').toggleClass('is-active');
        $('#menu-wrapper').toggleClass('is-active');
    });

    
};  