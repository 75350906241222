//////////////////////////////////////////////////////////////////
// This js module will allow for closing the cookie banner      //
// and sets an 'acceptance' cookie for 30 days                  //
//////////////////////////////////////////////////////////////////

export default function CookieBanner() {

    let $cookieBanner   =   jQuery('.cookie-banner');

    function cookieConsent() {

            var cduration = 30;
            var cname = 'Southbeach-CookieAcceptance';
            var cvalue ='accepted';

            if (!checkCookie(cname)) {
                $cookieBanner.removeClass('hide');
            }

            $cookieBanner.find('button').on('click', handleCookieClick)


            function  handleCookieClick() {
                createCookie(cname, cvalue, cduration);
                $cookieBanner.remove();
            }
    }

    cookieConsent();

    function createCookie(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000)); 
            var expires = "; expires="+date.toGMTString(); 
        }
        else var expires = "";
        
        document.cookie = name+"="+value+expires+"; path=/"; 
    }

    function checkCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

};