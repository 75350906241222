export default function Carousel() {
  console.log('cursor');
    const cursor = document.getElementById("cursor");

    const animateCursor = (e, interacting) => {
      const x = e.clientX - cursor.offsetWidth / 2,
            y = e.clientY - cursor.offsetHeight / 2;
      
      const keyframes = {
        transform: `translate(${x}px, ${y}px) scale(${interacting ? 6.5 : 1})`
      }
      
      cursor.animate(keyframes, { 
        duration: 400, 
        fill: "forwards" 
      });
    }

    const getCursorClass = type => {
      switch(type) {
        case "portfolio":
          return "portfolio-icon";
        default:
          return "fa-solid fa-arrow-up-right"; 
      }
    }

    window.onmousemove = e => {
      const interactable = e.target.closest(".panel"),
            interacting = interactable !== null;
      
      const icon = document.getElementById("cursor-icon");
      
      animateCursor(e, interacting);
      
      cursor.dataset.type = interacting ? interactable.dataset.type : "";
      
      if(interacting) {
        icon.className = getCursorClass(interactable.dataset.type);
      }
    }
};  