import $ from 'jquery';
window.jQuery = $;

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import 'slick-carousel';

import CookieBanner from './components/cookie-banner';
import InView from './components/inviewport';
import Type from './components/type';
import Cursor from './components/cursor';
import Carousel from './components/carousel';
import Navigation from './components/navigation';

jQuery(document).ready(() => {
  
  InView();
  Cursor();
  Navigation();
  Carousel();
});

$(window).scroll(function () {
   InView();
});

$.fn.isInViewport = function () {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $('.js-fade-in-up').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-fade-in-up');
        } 
    })

    $('.js-fade-in').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-fade-in');
        } 
    })

    $('.js-image-reveal').each(function(i, el){
        if ($(this).isInViewport()) {
            $(this).addClass('o-image-reveal');
        } 
    })

Type();